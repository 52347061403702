#filter-posts-container {
    padding-bottom: 10px;
    text-align: center;
}

#filter-posts-form {
    margin-bottom: 0;
    padding: 0;
    display: block;
    align-content: center;
    max-width: 100vw;
}

#filter-posts-toggle-container {
    display: none;
}

.filter-posts-select {
    display: inline-block;
    padding: 5px;
    width: 200px;
    margin: 5px 10px;
    background-color: var(--backgroundColor);
    border: 0;
    font-size: 20px;
    color: var(--color);
    border: var(--border);
}

.filter-posts-select:hover {
    cursor: pointer;
}

#tags-container {
    padding: 10px;
    max-height: 20vh;
    overflow-x: hidden;
    overflow-y: auto;
}

.tag-button {
    display: inline-block;
    padding: 5px 8px;
    margin: 2px;
    background-color: var(--backgroundColor);
    border: var(--border);
    color: var(--color);
    border-radius: 100px;
    font-size: 14px;
    word-wrap: break-word;
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

.tag-button:hover {
    cursor: pointer;
}

.selected-button {
    background-color: var(--color);
    color: var(--backgroundColor);
    border: none;
}

@media(max-width: 740px) {
    .hidden {
        display: none;
    }
    .filter-posts-select {
        display: block;
        width: 100%;
        margin: 5px auto;
    }
    #filter-posts-toggle-container {
        display: block;
        font-size: 18px;
        text-align: right;
        padding: 15px 15px 0 0;
    }
    .clickable:hover {
        cursor: pointer;
    }
    #filter-posts-toggle-container img {
        max-width: 25px;
        max-height: 25px;
    }
    #filter-posts-toggle-container p {
        display: inline;
        position: relative;
        bottom: 5px;
        left: 2px;
    }
}