.tip {
    width: calc(90vw - 30px);
    text-align: center;
    border: var(--border);
    color: var(--color);
    padding: 10px;
    border-radius: 5px;
    margin: 10px auto;
    display: block;
}

.remove-tip-button {
    width: 98%;
    max-width: 500px;
    background-color: var(--color);
    color: var(--backgroundColor);
    font-weight: 600;
    border-radius: 5px;
    padding: 10px 0px;
    cursor: pointer;
    margin: 15px auto 0px;
}

.toggle-show-read-tips {
    width: 90%;
    max-width: 300px;
    margin: 10px auto;
    display: block;
    border: var(--border);
}