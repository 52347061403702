#share-title {
    font-size: 30px;
    text-align: center;
    padding: 0;
    margin: 0;
    margin-bottom: 20px;
}

.share-button {
    margin-right: 10px;
}

.share-icon:hover {
    transform: scale(1.1);
}

#share-link-container {
    padding-top: 10px;
}

#share-link {
    color: var(--color);
    text-decoration: underline;
    display: inline;
}

#share-copy-link {
    color: var(--linkColor);
    display: inline;
    margin-left: 10px;
}

#share-copy-link:hover {
    cursor: pointer;
}

#share-copy-link:active {
    color: var(--color);
    text-decoration: underline;
}