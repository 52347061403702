#account {
    display: block;
    width: 350px;
    margin: 15vh auto;
}

@media(max-width: 400px) {
    #account {
        width: 90vw;
    }
}