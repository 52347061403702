#header-container {
    padding: 15px;
    height: 7vh;
    border-bottom: var(--border);
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    z-index: 2;
    background-color: var(--backgroundColor);
}

#header-logo {
    max-height: 100%;
    max-width: 80vw;
}

#header-logo:hover {
    cursor: pointer;
}

#header-options {
    display: inline;
    position: absolute;
    right: 2%;
}

.header-option {
    display: inline;
    padding: 10px 15px;
}

.header-option:hover {
    cursor: pointer;
    background-color: var(--hoverBackgroundColor);
}

.no-hover:hover {
    background-color: rgba(0, 0, 0, 0);
}

.header-option-icon {
    height: 18px;
    padding-right: 5px;
}

#hamburger:hover {
    cursor: pointer;
}

@media(max-width: 1200px){
    #header-options {
        height: 100vh;
        background-color: var(--backgroundColor);
        right: 0;
        top: calc(7vh + 15px);
        text-align: left;
        padding: 0;
        border-left: var(--border);
        width: fit-content;
    }
    .header-option {
        display: block;
        text-align: left;
        width: 100%;
        max-width: 300px;
    }

    #hamburger {
        position: absolute;
        top: 45%;
        right: 20px;
    }

    .hamburger-bar {
        width: 25px;
        height: 2px;
        background-color: var(--color);
        margin-bottom: 5px;
    }
}

@media(max-width: 500px){
    #header-logo {
        height: auto;
    }

    #header-container {
        height: 6vh;
        padding-bottom: 5px;
    }

    #header-options {
        top: calc(6vh + 5px);  
        max-width: 80vw;
    }
}