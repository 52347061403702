.post-container {
    display: inline-grid;
    max-width: 30%;
    padding: 10px;
    padding-bottom: 25px;
}

.post-container:hover {
    cursor: pointer;
}

.post-thumbnail-container {
    width: 100%;
    height: 20vh;
}

.post-thumbnail {
    display: block;
    max-width: 100%;
    max-height: 100%;
    overflow: scroll;
    margin: auto;
    position: relative;
    top: 50%;
    transform: translateY(-50%);
}

.post-type-icon {
    height: 1.1em;
    margin-right: 3px;
}

.post-title {
    font-size: 1.1em;
    margin: 0;
    margin-top: 10px;
}

.post-details {
    font-size: .9em;
    color: var(--colorSecondary);
    margin: 0;
    margin-top: 10px;
}

.post-bullet {
    margin: 0 8px;
}



@media(max-width: 500px) {
    .post-container {
        display: block;
        max-width: 95%;
        padding: 0;
        margin: 0 auto;
    }
}

@media(max-width: 610px) and (min-width: 501px) {
    .post-container {
        max-width: 45%;
    }
}

@media(min-width: 1200px) {
    .post-container {
        max-width: 23%;
    }
}

