#guidelines-container {
    padding: 0 3vw;
    margin: 0 auto;
    width: fit-content;
}

.guidelines-ul {
    max-width: 900px;
}

.guidelines-ul p {
    margin: 10px 0;
}

.guidelines-title {
    font-size: 3em;
}

.guidelines-li {
    font-size: 1.1em;
    padding: 8px 0;
}

.guidelines-section-title {
    font-size: 2em;
}

@media(max-width: 700px){
    .guidelines-title {
        font-size: 2em;
    }

    .guidelines-li {
        font-size: 1em;
    }

    .guidelines-section-title {
        font-size: 1.5em;
    }
}