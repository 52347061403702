body {
  --backgroundColor: white;
  --hoverBackgroundColor: rgb(240, 240, 240);
  --color: black;
  --colorSecondary: rgb(70, 70, 70);
  --border: 1px solid rgb(200, 200, 200);
  --formLabelColor: black;
  --modalExitBorder: 2px solid black;
  --linkColor: blue;
  --hoverFilter: brightness(.5);
  --focusOutline: 2px solid blue;
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: var(--backgroundColor);
  color: var(--color);
  padding-top: calc(7vh + 31px);
  overflow-wrap: break-word;
  padding-bottom: 8vh;
}

@media(max-width: 500px) {
  body {
    padding-top: calc(6vh + 21px);
  }
}

*::-webkit-scrollbar {
  background-color: rgba(0, 0, 0, 0);
  width: 8px;
  border-left: var(--border);
}

*::-webkit-scrollbar-thumb {
  background-color: var(--color);
  border-radius: 2px;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}