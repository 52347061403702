.view-post-media {
    width: 100%;
    margin: 0 auto;
    margin-bottom: 15px;
}

#view-post-title {
    margin: 0;
    padding-bottom: 10px;
}

.view-post-sub-title {
    font-size: 20px;
    margin: 0;
    margin-top: 10px;
    padding: 5px 0;
}

.view-post-link {
    font-size: 14px;
    padding-left: 10px;
    color: var(--linkColor);
    display: block;
    margin-bottom: 10px;
    text-decoration: underline;
}

.view-post-link:hover {
    cursor: pointer;
}

.view-post-date {
    font-size: 14px;
}

#view-post-edit-button {
    background-color: rgb(19, 19, 19);
    border: 0;
    font-size: 20px;
    color: white;
    margin-top: 10px;
    width: 100%;
}

#view-post-edit-button:hover {
    cursor: pointer;
}

.view-post-description {
    margin: 0;
}

#gallery-tabs-container {
    display: flex;
    flex-direction: row;
}

.gallery-tab {
    width: 5px;
    height: 5px;
    border-radius: 100%;
    border: var(--border);
    margin: 0px 2px;
}

.gallery-tab-active {
    background-color: grey;
    width: 7px;
    height: 7px;
    border: none;
}

@media(max-width: 600px){
    #view-post-modal {
        margin: 0;
        width: auto;
        height: auto;
        top: 35px;
        left: 15px;
        right: 15px;
        bottom: 15px
    }

    #view-post-title {
        font-size: 25px;
    }

    .view-post-link-title {
        font-size: 15px;
    }
}