form {
    display: flex;
    flex-direction: column;
    width: 90vw;
    max-width: 400px;
    margin: 25px auto 20px;
}

.form-title {
    margin: 0;
    padding: 0;
    font-weight: 300;
}

.form-label {
    color: var(--colorSecondary);
    font-size: 14px;
    margin: 7px 0px 3px 3px;
}

.form-input {
    background-color: var(--backgroundColor);
    color: var(--color);
    font-size: 18px;
    border: 0;
    /*var(--border);*/
    border-bottom: var(--border);
    margin: none;
    margin-bottom: 3px;
    padding-left: 3px;
    width: calc(100% - 3px);
}

.form-input:focus {
    border-width: 1px;
    border-color: var(--color);
    outline: none;
}

.file-input {
    all: unset;
    display: inline-block;
}

.form-textarea {
    height: 100px;
    resize: none;
    border: var(--border);
    background-color: var(--backgroundColor);
    color: var(--color);
}

.form-error {
    color: red;
    font-size: 12px;
}

.form-button {
    background-color: rgb(19, 19, 19);
    border: 0;
    font-size: 20px;
    color: white;
    margin-top: 10px;
    width: 100%;
    padding: 5px;
}

.form-button:hover {
    cursor: pointer;
}

.form-toggle {
    color: var(--colorSecondary);
    font-size: 14px;
    padding: 5px 0;
}

.form-toggle:hover {
    cursor: pointer;
}

input {
    position: relative;
}

input[type="date"]::-webkit-calendar-picker-indicator {
    background: transparent;
    bottom: 0;
    color: transparent;
    cursor: pointer;
    height: auto;
    left: 0;
    position: absolute;
    right: 0;
    top: 0;
    width: auto;
}

input:focus {
    outline: none;
}

input:-webkit-autofill, input:-webkit-autofill:hover, input:-webkit-autofill:focus, textarea:-webkit-autofill, textarea:-webkit-autofill:hover, textarea:-webkit-autofill:focus, select:-webkit-autofill, select:-webkit-autofill:hover, select:-webkit-autofill:focus {
    -webkit-text-fill-color: var(--color);
    -webkit-box-shadow: 0 0 0px 1000px var(--backgroundColor) inset;
    box-shadow: 0 0 0px 1000px var(--backgroundColor) inset;
    transition: background-color 5000s ease-in-out 0s;
}

select {
    appearance: none;
    -webkit-appearance: none;
}

input[type='date'][value=""] {
    color: var(--backgroundColor);
}

input[type='date'] {
    appearance: none;
    -webkit-appearance: none;
}

#top-of-page-ad {
    height: 8vh;
    background-color: grey;
    margin-bottom: 10px;
    text-align: center;
    font-size: 20px;
}

#bottom-of-page-ad {
    height: 8vh;
    background-color: grey;
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    text-align: center;
    font-size: 20px;
}

#submit-tip-button {
    width: fit-content;
    padding: 10px;
    border-radius: 2px;
    background-color: var(--color);
    color: var(--backgroundColor);
    position: fixed;
    bottom: 10px;
    right: 10px;
    cursor: pointer;
    box-shadow: 0;
    animation: glow 4.5s ease infinite;
}

@keyframes glow {
    0%{
        box-shadow: 0;
    }
    50%{
        box-shadow: 0 0 5px var(--color), 0 0 15px var(--color);
    }
    100%{
        box-shadow: 0;
    }
}