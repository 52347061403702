#donate-container {
    max-width: 700px;
    margin: 0 auto;
    padding-bottom: 25px;
}

#donate-container h2 {
    text-align: center;
}

#donate-title {
    text-align: center;
    
}

.donate-option {
    display: inline-grid;
    width: 30%;
    text-align: center;
    padding: 30px 10px;
}

.donate-option:hover {
    cursor: pointer;
    background-color: rgb(19, 19, 19);
}

.donate-option-monthly {
    color: grey;
    margin: 0;
}

.donate-amount {
    font-size: 3em;
    margin: 5px;
}



#donate-custom h2 {
    text-align: center;
}

@media(max-width: 600px) {
    .donate-option{
        display: block;
        width: 95%;
        margin: 0 auto;
    }
}