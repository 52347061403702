.interaction-container {
    padding-right: 15px;
    display: inline;
    color: var(--colorSecondary);
    margin: 0;
    margin-left: 8px;
}

.interaction-container:hover {
    cursor: pointer;
    filter: var(--hoverFilter);
}

.post-interaction-icon {
    height: 15px;
    padding-right: 5px;
}