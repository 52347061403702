#loading {
    position: relative;
}

#loading span {
    position: relative;
    display: inline-block;
    animation: wave 1s ease-in-out infinite;
    animation-delay: calc(0.1s * var(--i));
}

@keyframes wave {
    0% {
        transform: translateY(0px)
    }
    20% {
        transform: translateY(-5px)
    }
    40%,100% {
        transform: translateY(0)
    }
}