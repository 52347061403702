#modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    background-color: rgba(0, 0, 0, 0.9);
    z-index: 3;
}

#modal-body {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: var(--backgroundColor);
    border: var(--border);
    border-radius: 3px;
    padding: 20px;
    max-height: 90vh;
    overflow-y: auto;
    width: 85vw;
}

#modal-exit {
    background: var(--backgroundColor);
    width: 20px;
    height: 20px;
    position: relative;
    left: calc(100% - 10px);
    top: -10px;
    margin-bottom: 10px;
}

#modal-exit:after {
    content: '';
    height: 20px;
    border-left: var(--modalExitBorder);
    position: absolute;
    transform: rotate(45deg);
    left: 9px;
}

#modal-exit:before {
    content: '';
    height: 20px;
    border-left: var(--modalExitBorder);
    position: absolute;
    transform: rotate(-45deg);
    left: 9px;
}

#modal-exit:hover {
    cursor: pointer;
}